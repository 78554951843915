/* eslint-disable prettier/prettier */
import { DropdownOption } from '../../new/typings';
import { selectStyles } from '../../../../helpers/utils';
import React from 'react';
import Select from 'react-select';

const HeaderComponent: React.FC<any> = ({
  queue,
  options,
  statusFilter,
  setStatusFilter,
  showDownloadIcon,
  authorizedOptions,
  authorizedFilter,
  setAuthorizedFilter,
  setPartnersFilter,
  partnerFilter,
  partnerOptions,
  countryOptions,
  countryFilter,
  setCountryFilter,
  setSortCreatedByFilter,
  sortCreatedByFilter,
  setSortUpdatedByFilter,
  sortUpdatedByFilter
}) => {
  return (
    <thead>
      <tr>
        <th>Claim No.</th>
        <th className="text-center">Make</th>
        <th className="text-center">Model</th>
        <th className="text-center">Registration</th>
        <th className="text-center width-120">
          {' '}
          <Select
            styles={selectStyles}
            options={authorizedOptions}
            value={authorizedFilter}
            onChange={(selectedOption: DropdownOption) => {
              setAuthorizedFilter(selectedOption);
            }}
          />{' '}
        </th>
        <th className="text-center">
          <Select
            styles={selectStyles}
            options={partnerOptions}
            value={partnerFilter}
            onChange={(selectedOption: DropdownOption) => {
              setPartnersFilter(selectedOption);
            }}
          />
        </th>
        <th className="text-center">
          <Select
            styles={selectStyles}
            options={countryOptions}
            value={countryFilter}
            onChange={(selectedOption: DropdownOption) => {
              setCountryFilter(selectedOption);
            }}
          />
        </th>
        <th className="text-center width-120">
          {' '}
          <Select
            styles={selectStyles}
            options={options}
            value={statusFilter}
            onChange={(selectedOption: DropdownOption) => {
              setStatusFilter(selectedOption);
            }}
          />{' '}
        </th>
        <th className="text-center">Days Open</th>
        <th className="text-center">
          Created{' '}
          <span
            className="sort-btn "
            onClick={() => setSortCreatedByFilter && setSortCreatedByFilter()}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
          >
            {sortCreatedByFilter === 'desc' ? '▼' : '▲'}
          </span>
        </th>
        <th className="text-center">
          Updated{' '}
          <span
            className="sort-btn "
            onClick={() => setSortUpdatedByFilter && setSortUpdatedByFilter()}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
          >
            {sortUpdatedByFilter === 'desc' ? '▼' : '▲'}
          </span>
        </th>
        {showDownloadIcon && <th></th>}
        <th></th>
      </tr>
    </thead>
  );
};

export default HeaderComponent;
